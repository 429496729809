import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import AnimatedImage from 'components/image/animated-image'
import AnimatedButton from 'components/buttons/animated-button'
import styles from './call-to-action.module.css'

const query = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "hex-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CallToAction = ({ content, page_link, title, title_emphasis, cta_image }) => {
  const data = useStaticQuery(query);
  const image = {
    fluid: cta_image.localFile.childImageSharp.fluid,
    alt_text: cta_image.alt_text
  }
  const backgroundImage = data.backgroundImage.childImageSharp.fluid;
  let path = page_link.url.substr(page_link.url.indexOf('/', 7) + 1)

  if (path.substring(0, 1) !== '/') {
    path = `/${path}`
  }

  return (
    <>
      <span className={styles.spacer}></span>
      <section className={styles.container}>
        <div className={styles.background}>
          <Img fluid={backgroundImage} alt={'Abstract background shapes'} className={styles.backgroundImage} />
        </div>

        <div className={styles.content}>
          <div className={styles.contentBorder}></div>
          <h1 className={styles.title}>
            { title_emphasis && <span className={styles.accentTitle}>[{title_emphasis}]</span>}
            {title}
          </h1>

          <p>
            <span className={styles.accent}></span>
            {content}
          </p>

          <AnimatedButton
            message={page_link.title}
            light
            className={styles.button}
            to={path}
          />
        </div>

        <div className={styles.imageWrapper}>
          <AnimatedImage image={image} />
        </div>
      </section>
    </>
  )
}

CallToAction.defaultProps = {
  content: '',
  cta_image: {},
  page_link: {
    target: '',
    title: '',
    url: ''
  },
  title: '',
  title_emphasis: ''
}

export default CallToAction