import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './giant-button.module.css'
const GiantButton = ({ page_link: { target, url }, title, subtitle }) => {
  const path = url.substr(0, 1) === '/' ? url : url.substr(url.indexOf('/', 7) + 1)

  if (url.startsWith('mailto')) {
    return (
      <section className={styles.container}>
        <a href={url} className={styles.link}>
          <span>{ subtitle }</span>
          <h1>{ title }</h1>
          <FontAwesomeIcon icon={['fal', 'arrow-right']} className={styles.icon} />
        </a>
      </section>
    )
  } else {
    return (
      <section className={styles.container}>
        <Link to={path} target={target} className={styles.link}>
          <span>{ subtitle }</span>
          <h1>{ title }</h1>
          <FontAwesomeIcon icon={['fal', 'arrow-right']} className={styles.icon} />
        </Link>
      </section>
    )
  }
}

GiantButton.defaultProps = {
  page_link: {},
  title: '',
  subtitle: ''
}

export default GiantButton