import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import styles from './service-list.module.css'

const ServiceArrow = () => (
  <div className={styles.arrowContainer}>
    <span className={styles.arrowLeft}></span>
    <span className={styles.arrowCenter}></span>
    <span className={styles.arrowRight}></span>
  </div>
)

const ServiceList = ({ services, slug }) => {
  const specialRegex = /[^\w\s]/gi
  const spaceRegex = / /gi

  let nodes = services.map((node, index) => {
    let key = node.title.replace(specialRegex, '')
    key = node.title.replace(spaceRegex, '_')
    
    return <ServiceListItem key={key + index} currentIndex={index} {...node} slug={slug} />
  })

  if (slug === 'process') {
    for (var index = 2; index < nodes.length; index += 3) {
      nodes.splice(index, 0, <ServiceArrow key={index}></ServiceArrow>)
    }
  }

  return (
    <section className={styles.container}>
      { nodes }
    </section>
  )
}

const ServiceListItem = ({ currentIndex, content, icon, title, slug }) => {
  let count = currentIndex + 1
  count = count.toString().padStart(2, '0')

  return (
    <section className={classnames(styles.node, slug === 'process' && styles.process)}>
      <span className={styles.indicator}>{ count }</span>

      <div className={styles.titleContainer}>
        <FontAwesomeIcon icon={['fal', icon]} className={styles.icon} />
        <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h3>
      </div>

      <AccentBlock />

      <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }}></div>
    </section>
  )
}

const AccentBlock = () => <span className={styles.accent}></span>

ServiceList.defaultProps = {
  services: {}
}

ServiceListItem.defaultProps = {
  content: '',
  currentIndex: 0,
  icon: '',
  title: ''
}

export default ServiceList