import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import axios from 'axios'
import { motion, useAnimation } from 'framer-motion'

import { ContactFormObject } from 'components/layouts/contact-form'
import styles from './contact-form-with-info.module.css'

const Feedback = ({ children, success = false, show = false }) => {
  const controls = useAnimation()

  useEffect(() => {
    if (show) {
      animateIn()
    } else {
      animateOut()
    }
  }, [show])

  const animateIn = () => {
    controls.start({
      opacity: 1,
      scale: 1
    })
  }

  const animateOut = () => {
    controls.start({
      opacity: 0,
      scale: 0.5
    })
  }

  return (
    <motion.div 
      className={success && styles.success}
      animate={controls}
      initial={{
        opacity: 0,
        scale: 0.8
      }}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 20
      }}
    >
      <span>{ children }</span>
    </motion.div>
  )
}

const ContactFormWithInfo = ({ title, information = '' }) => {
  const [feedbackVisible, showFeedback] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          address {
            address_1
            address_2
            city
            state
            zip
          }
          phone_number
        }
      }
    }
  `)

  const { options } = data.wordpressAcfOptions

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    message: ''
  }

  const validate = (values) => {
    let errors = {}

    if (!values.firstName) {
      errors.firstName = 'First name is required'
    }

    if (!values.lastName) {
      errors.lastName = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (!values.message) {
      errors.message = 'Message cannot be blank'
    } else if (values.message.length < 10) {
      errors.message = 'Message cannot be less than 10 characters long'
    }

    return errors
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    axios.post(
      '/.netlify/functions/submission',
      values,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(result => {
      setSubmitting(false)
      resetForm()

      showFeedback(true)
      
      setTimeout(() => {
        showFeedback(false)
      }, 5000)
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <h2 className={styles.title}>{ title }</h2>
        <div dangerouslySetInnerHTML={{ __html: information }} className={styles.content}></div>
      </div>

      <div className={styles.right}>
        <ContactFormObject validate={validate} handleSubmit={handleSubmit} initialValues={initialValues} />
        <Feedback success show={feedbackVisible}>{ 'Thanks for reaching out! A representative will contact you as soon as possible.' }</Feedback>
      </div>
    </div>
  )
}

ContactFormWithInfo.defaultProps = {
  title: ''
}

export default ContactFormWithInfo