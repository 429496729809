import React from 'react'
import classnames from 'classnames'

import AnimatedButton from 'components/buttons/animated-button'
import styles from './bordered-content.module.css'

const BorderedContentWithButton = ({ content, page_link, bottom_border }) => {
  let path = page_link.url.substr(page_link.url.indexOf('/', 7) + 1)

  if (path.substring(0, 1) !== '/') {
    path = `/${path}`
  }

  return (
    <section className={classnames(styles.container, bottom_border ? styles.fullContainer : '')}>
      <div className={'content-block-text'} dangerouslySetInnerHTML={{ __html: content }}></div>
      <span className={styles.accent}></span>
      <AnimatedButton to={path} message={page_link.title} className={ bottom_border ? styles.full : '' } />
    </section>
  )
}

BorderedContentWithButton.defaultProps = {
  bottom_border: false,
  content: '',
  page_link: {
    target: '',
    title: '',
    url: ''
  }
}

export default BorderedContentWithButton