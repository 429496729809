import React from 'react'
import classnames from 'classnames'

import styles from './borderless-content.module.css'

const BorderlessContent = ({ content, title, subtitle, two_columns }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{ title} </h2>
      { subtitle && <span className={styles.subtitle}>{ subtitle }</span>}
      <div dangerouslySetInnerHTML={{ __html: content }} className={classnames(styles.content, two_columns && styles.columns)}></div>
    </section>
  )
}

BorderlessContent.defaultProps = {
  content: '',
  title: '',
  subtitle: '',
  two_columns: ''
}

export default BorderlessContent