import React from 'react'
import classnames from 'classnames'

import styles from './horizontal-divider.module.css'

const HorizontalDivider = ({ color }) => {
  let classes = ''

  switch (color) {
    case 'seagreen':
      classes = classnames(styles.line, styles.seagreen)
      break;

    case 'navy':
      classes = classnames(styles.line, styles.navy)
      break;

    case 'blue':
      classes = classnames(styles.line, styles.blue)
      break;

    case 'bluegray':
      classes = classnames(styles.line, styles.bluegray)
      break;

    case 'lightblue':
    default:
      classes = classnames(styles.line, styles.lightblue)
  }
  
  return <section className={classes}></section>
}

HorizontalDivider.defaultProps = {
  color: 'lightblue'
}

export default HorizontalDivider