import React from 'react'

import BorderedContent from 'components/layouts/bordered-content'
import BorderedContentWithButton from 'components/layouts/bordered-content-with-button'
import BorderlessContent from 'components/layouts/borderless-content'
import CallToAction from 'components/layouts/call-to-action'
import ContactForm from 'components/layouts/contact-form'
import ContactFormWithInfo from 'components/layouts/contact-form-with-info'
import ImageCollage from 'components/layouts/image-collage'
import GiantButton from 'components/layouts/giant-button'
import HorizontalDivider from 'components/layouts/horizontal-divider'
import ServiceList from 'components/layouts/service-list'
import TeamMembers from 'components/layouts/team-members'

const createLayout = (acf, slug = '') => {
  return acf.map((rowLayout) => {
    switch (rowLayout.__typename) {
      case 'WordPressAcf_bordered_content':
        return <BorderedContent key={rowLayout.id} {...rowLayout} />

      case 'WordPressAcf_bordered_content_with_button':
        return <BorderedContentWithButton key={rowLayout.id} {...rowLayout} />

      case 'WordPressAcf_borderless_content':
        return <BorderlessContent key={rowLayout.id} {...rowLayout} />

      case 'WordPressAcf_call_to_action':
        return <CallToAction key={rowLayout.id} {...rowLayout} />
                
      case 'WordPressAcf_contact_form':
        return <ContactForm key={rowLayout.id} {...rowLayout} />

      case 'WordPressAcf_giant_button':
        return <GiantButton key={rowLayout.id} {...rowLayout} />

      case 'WordPressAcf_horizontal_divider':
        return <HorizontalDivider key={rowLayout.id} {...rowLayout} />
        
      case 'WordPressAcf_image_collage':
        return <ImageCollage key={rowLayout.id} {...rowLayout} />
        
      case 'WordPressAcf_service_list':
        return <ServiceList key={rowLayout.id} {...rowLayout} slug={slug} />
        
      case 'WordPressAcf_team_members':
        return <TeamMembers key={rowLayout.id} {...rowLayout} />

      case 'WordPressAcf_contact_form_with_info':
        return <ContactFormWithInfo key={rowLayout.id} {...rowLayout} />

      default:
        return null
    }
  })
}

export { createLayout }