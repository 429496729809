import React from 'react'
import classnames from 'classnames'

import styles from './bordered-content.module.css'

const BorderedContent = ({ content, bottom_border }) => {
  return (
    <section className={classnames(styles.container, bottom_border ? styles.fullContainer : '')}>
      <div className={classnames(styles.content, 'content-block-text')} dangerouslySetInnerHTML={{ __html: content }}></div> 
    </section>
  )
}

BorderedContent.defaultProps = {
  bottom_border: false,
  content: '',
}

export default BorderedContent