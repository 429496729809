import React, { useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { TweenMax, Power2 } from 'gsap'
import axios from 'axios'

import styles from './contact-form.module.css'

export const FormGroup = ({ children }) => (
  <div className={styles.formGroup}>
    { children }
  </div>
)

export const CustomInput = ({ label, field, className, textarea = false, form: { setFieldValue, touched, errors }, ...props}) => {
  const labelRef = useRef(null)
  const { value } = field
  let labelRaised = false

  const handleFocus = () => {
    labelRaised = true
    TweenMax.to(labelRef.current, 0.2, { ease: Power2.ease, y: -30 })
  }

  const handleBlur = () => {
    if (!value) {
      labelRaised = false
      TweenMax.to(labelRef.current, 0.2, { ease: Power2.ease, y: 5 })
    }
  }

  const handleChange = (e) => {
    setFieldValue(field.name, e.target.value)

    if (e.target.value && !labelRaised) {
      labelRaised = true
      TweenMax.to(labelRef.current, 0.2, { ease: Power2.ease, y: -30 })
    }
  }

  return (
    <div className={styles.inputWrapper}>
      <label ref={labelRef} className={classnames(styles.label, errors[field.name] && styles.errorLabel)}>{ label }</label>
      
      { 
        textarea
        ? <textarea rows={4} {...field} {...props} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} className={classnames(className, errors[field.name] && styles.errorInput)}></textarea>
        : <input 
            type='text' 
            {...field} 
            {...props} 
            onFocus={handleFocus} 
            onBlur={handleBlur} 
            onChange={handleChange} 
            className={classnames(className, errors[field.name] && styles.errorInput)}
          />
      }

      { touched[field.name] && errors[field.name] && <div className={styles.errorMessage}>{ errors[field.name] }</div> }
    </div>
  )
}

export const ContactFormObject = ({ validate, handleSubmit, initialValues }) => (
  <Formik
    initialValues={initialValues}
    validate={validate}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form className={styles.form}>
        <FormGroup>
          <Field
            type="text"
            name="firstName"
            id="firstName"
            label='First Name'
            className={styles.input}
            component={CustomInput}
          />
          
          <Field
            type="text"
            name="lastName"
            id="lastName"
            label='Last Name'
            className={styles.input}
            component={CustomInput}
          /> 
        </FormGroup>

        <FormGroup>
          <Field
            type="email"
            name="email"
            id="email"
            label='Email Address'
            className={styles.input}
            component={CustomInput}
          />
          <Field
            type="text"
            name="company"
            id="companyName"
            label='Company Name'
            className={styles.input}
            component={CustomInput}
          />
        </FormGroup>

        <FormGroup>
          <Field
            name="message"
            id="message"
            label='Message'
            textarea
            className={styles.textarea}
            component={CustomInput}
          />
        </FormGroup>

        <button type='submit' disabled={isSubmitting} className={styles.submitButton}><FontAwesomeIcon icon={['far', 'arrow-right']} className={styles.icon} />Submit</button>
      </Form>
    )}
  </Formik>
)

const ContactForm = ({ content }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    message: ''
  }

  const validate = (values) => {
    let errors = {}

    if (!values.firstName) {
      errors.firstName = 'First name is required'
    }

    if (!values.lastName) {
      errors.lastName = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (!values.message) {
      errors.message = 'Message cannot be blank'
    } else if (values.message.length < 10) {
      errors.message = 'Message cannot be less than 10 characters long'
    }

    return errors
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    axios.post(
      '/.netlify/functions/submission',
      values,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(result => {
      setSubmitting(false)
      resetForm()
    })
  }
  
  return (
    <section className={styles.container}>
      <div className={classnames('content-block-text', styles.content)} dangerouslySetInnerHTML={{ __html: content }}></div>
      <ContactFormObject validate={validate} handleSubmit={handleSubmit} initialValues={initialValues} />
    </section>
  )
}

ContactForm.defaultProps = {
  content: '<p>Test Content</p>'
}

export default ContactForm