import React from 'react'

import AnimatedImage from 'components/image/animated-image'
import styles from './team-members.module.css'

const TeamMember = ({ image, name, title }) => (
  <div className={styles.node}>
    <AnimatedImage image={image} className={styles.nodeImage} />
    <h3 className={'team'}>{ name }</h3>
    <span>{ title }</span>
  </div>
)

const TeamMembers = ({ team, title }) => {
  if (!team && team.length < 1) {
    return
  }

  const teamMembersList = team.map((teamMember, index) => {
    const image = teamMember.picture.localFile.childImageSharp.fluid
    const { name, title } = teamMember
    
    return <TeamMember image={image} name={name} title={title} key={index + name} />
  })

  return (
    <section className={styles.container}>
      <span className={styles.title}>{ title }</span>
      <div className={styles.nodeWrapper}>
        { teamMembersList }
      </div>
    </section>
  )
}

TeamMember.defaultProps = {
  image: {},
  name: '',
  title: ''
}

TeamMembers.defaultProps = {
  team: [],
  title: ''
}

export default TeamMembers