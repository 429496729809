import React from 'react'
import Particles from 'react-particles-js'
import classnames from 'classnames'

// Import all SVG shapes
import AnimatedImage from 'components/image/animated-image'
import config from './image-collage.config.json'
import styles from './image-collage.module.css'

const ImageCollage = ({ alternative_arrangement, image_gallery }) => {
  const images = image_gallery.map((image, index) => {
    const imageObject = {
      fluid: image.localFile.childImageSharp.fluid,
      alt_text: image.alt_text
    }

    return (
      <div className={!alternative_arrangement ? styles.imageWrapper : classnames(styles.imageWrapper, styles.imageAlt)} key={index}>
        <AnimatedImage image={imageObject} />
      </div>
    )
  })

  return (
    <section className={styles.container}>
      <Particles params={config} className={styles.canvasWrapper} />
      <div className={styles.imageContainer}>
        { images }
      </div>
    </section>
  )
}

export default ImageCollage